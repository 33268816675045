import React, { Component } from 'react'
import { Link, Navigate } from 'react-router-dom';
import "./Homepage.css";
export class Homepage extends Component {

  componentDidMount() {
  
  }

  render() {
    return (
      <>
hrllo



 
      </>
    )
  }
}

export default Homepage