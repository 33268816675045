import axios from 'axios';
import React, { Component } from 'react'
import { useParams } from 'react-router-dom';
import CourseSingle from '../../Component/Courses/CourseSingle';
import Spinner from '../../Component/Spinner/Spinner';
export class CoursesPage extends Component {
  state = {
    dept_id:"",
    sem_id:"",
    loading:true,
    coursesData:""
  }
  componentDidMount(){
    this.props.changeCoverTitle("Course Will Show Here")
    var sem_id = this.props.params.sem_id;
    var dept_id= this.props.params.dept_id;
    this.setState({dept_id:dept_id, sem_id:sem_id})

    axios.get(`?getcoursesbysemanddept&sem_id=${sem_id}&dept_id=${dept_id}`)
    .then((response)=>{
     this.setState({coursesData:response.data, loading:false});
   
    })
   
  }
  render() {
    var coursesDataRender = "";
    var coursesData = this.state.coursesData;
    coursesDataRender = Object.keys(coursesData)
    .map((i)=>{
    var course_name  = coursesData[i].course_name ;
    var course_code = coursesData[i].course_code ;
    var course_id = coursesData[i].course_id ;
     var url ="/department/"+this.state.dept_id+ "/semester/"+this.state.sem_id+ "/course/"+course_id;
    
    
return <CourseSingle url={url} course_name={course_name}  course_code={course_code}  />
    });    

    return (
      <>
        <div class="row">
 
{this.state.loading?<Spinner/>:coursesDataRender}
</div>
      </>
    )
  }
}

export default (props) => (
    <CoursesPage
        {...props}
        params={useParams()}
    />
  );