import React, { Component } from 'react'
import { Link } from 'react-router-dom'

export class NavBar2 extends Component {
    state = {
        navbarshow:false,
        profilebarshow:false
    }

    navBarClose=()=>{
        this.setState({
            navbarshow:false,
            profilebarshow:false
        })
    }
    navBarHandler=()=>{
        this.setState({
            navbarshow:this.state.navbarshow?false:true
        })
    }
    profileBarHandler=()=>{
        this.setState({
            profilebarshow:this.state.profilebarshow?false:true
        })
    }
  render() {
    return (
      <>
         <nav class="navbar navbar-expand-lg fixed-top navbar-dark py-3 " style={{ "background": "#000066" }}>
          <div class="container"> <Link  onClick={this.navBarClose}class="navbar-brand font-weight-bold text-uppercase" to="/">MEC Dairy</Link>
            
            <button onClick={this.navBarHandler} class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class={this.state.navbarshow?"collapse navbar-collapse show":"collapse navbar-collapse"} id="navbarSupportedContent">
              <ul class="navbar-nav ml-auto font-weight-bold">
                <li class="nav-item">
                  <Link  onClick={this.navBarClose}class="nav-link" aria-current="page" to="/">Home</Link>
                </li>
                <li class="nav-item">
                  <Link  onClick={this.navBarClose}class="nav-link" to="/teachers">Teachers</Link>
                </li>

                <li class="nav-item">
                  <Link  onClick={this.navBarClose}class="nav-link" to="/department">Department</Link>
                </li>
                <li class="nav-item">
                  <Link  onClick={this.navBarClose}class="nav-link" to="/notice">Notice</Link>
                </li>
                <li class="nav-item">
                  <Link  onClick={this.navBarClose}class="nav-link" to="/upload/add">Upload</Link>
                </li>

                <li class="nav-item dropdown">
                  <a onClick={this.profileBarHandler} class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Profile
                  </a>
                 {this.state.profilebarshow?<ul class="dropdown-menu show" aria-labelledby="navbarDropdown">
                    <li><a class="dropdown-item" href="#">My Profile</a></li>
                    <li><a class="dropdown-item" href="#">Settings</a></li>
                    <li><hr class="dropdown-divider" /></li>
                    <li><button onClick={this.props.logoutHandler} class="dropdown-item" href="#">Logout</button></li>
                  </ul>:""} 
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <div id="carouselExampleCaptions" class="carousel slide carousel-fade pt-5" data-ride="carousel">
          <ol class="carousel-indicators">
            <li data-target="#carouselExampleCaptions" data-slide-to="0" class="active"></li>
            <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
            <li data-target="#carouselExampleCaptions" data-slide-to="2"></li>
          </ol>
          {/* <div class="carousel-inner" >
            <div class="carousel-item active" style={{ "background": "#000066" }}>
     
              <div class="carousel-caption">
                <h5 class="display-4 h4-md mb-4 font-weight-bold ">{this.props.coverTitle}</h5>
                <p class="h4 mb-5 pb-3 text-white-50 invisible ">Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                <a href="" class="btn btn-lg btn-danger invisible ">View More</a>
              </div>
            </div>


          </div> */}
        </div>
      
      </>
    )
  }
}

export default NavBar2