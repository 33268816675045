import React, { Component } from 'react'
import Footer from './Footer'
import Header from './Header'

export class Layout extends Component {
  render() {
    return (
      <>
        {this.props.isLogged?<><Header setLoggedOut={this.props.setLoggedOut} coverTitle={this.props.coverTitle}/>
      <div class="container mt-5">
      <div class="container mt-5">
       {this.props.children}
      </div></div>
      <Footer/></>:this.props.children}
      </>
    )
  }
}

export default Layout