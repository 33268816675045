import axios from 'axios';
import React, { Component } from 'react'
import { Navigate } from 'react-router-dom';
import RedirectToPage from '../../Component/RedirectToPage';
import "./LoginPages.css";
export class LoginPages extends Component {
    state = {
        isLogged:false,
        redirect:false,
        username :"",
        password:"",
        loginerror:false

    }
    loginButtonHandler = ()=>{
        var bodyFormData = new FormData();
        bodyFormData.append('username',  this.state.username);
        bodyFormData.append('password',  this.state.password);
        axios.post("?login",bodyFormData)
        .then((response)=>{
            if(response.data==0){
                this.setState({redirect:false,loginerror:true})
              
            }else{
                this.props.setLoggedin(response.data)
                this.setState({redirect:true,loginerror:false})
                 
                
            }
           
        })
        
        
       
    }

    inputHandler  = (e)=>{
this.setState({
    [e.target.id] : e.target.value
})
    }
  render() {
  
    return (
      <>
      
     <div class="login-page ">
     {this.props.isLogged?<RedirectToPage url="/courses"/>:""}
        <div class="container">
            <div class="row">
                <div class="col-lg-10 offset-lg-1">
                  <h3 class="mb-3">Login Now</h3>
                    <div class="bg-white shadow rounded">
                        <div class="row">
                            <div class="col-md-7 pe-0">
                                <div class="form-left h-100 py-5 px-5">
                                    <form action="" class="row g-4">
                                            <div class="col-12">
                                                <label>Username<span class="text-danger">*</span></label>
                                                <div class="input-group">
                                                    <div class="input-group-text"><i class="bi bi-person-fill"></i></div>
                                                    <input type="text" onChange={this.inputHandler} class="form-control" id='username' placeholder="Enter Username"/>
                                                </div>
                                            </div>

                                            <div class="col-12">
                                                <label>Password<span class="text-danger">*</span></label>
                                                <div class="input-group">
                                                    <div class="input-group-text"><i class="bi bi-lock-fill"></i></div>
                                                    <input type="text" id='password'  onChange={this.inputHandler} class="form-control" placeholder="Enter Password"/>
                                                </div>
                                            </div>

                                            <div class="col-sm-6">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox" id="inlineFormCheck"/>
                                                    <label class="form-check-label" for="inlineFormCheck">Remember me</label>
                                                </div>
                                            </div>

                                            {/* <div class="col-sm-6">
                                                <a href="#" class="float-end text-primary">Forgot Password?</a>
                                            </div> */}

                                            {this.state.loginerror?<div class="alert alert-danger" role="alert">
 Login Error! Recheck And Login Again
</div>:""}


                                            <div class="col-12">
                                                <button type="button" onClick={this.loginButtonHandler} class="btn btn-primary px-4 float-end mt-4">login</button>
                                            </div>
                                    </form>
                                </div>
                            </div>
                            <div class="col-md-5 ps-0 d-none d-md-block">
                                <div  id='imageset' style={{"background":"url('/assets/img/campus.jpg')"}} class="form-right h-100 bg-primary text-white text-center pt-5">
                                    {/* <img src='/assets/img/campus.jpg' className='img-fluid'/> */}
                                    {/* style={{"background":"url('/assets/img/campus.jpg')"}} */}
                                    {/* <i class="bi bi-bootstrap"></i>
                                    <h2 class="fs-1">Welcome Back!!!</h2> */}
                                </div>
                            </div>
                        </div>
                    </div>
                
                </div>
            </div>
        </div>
    </div>
      
      </>
    )
  }
}

export default LoginPages