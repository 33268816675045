import axios from 'axios';
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Spinner from '../../Component/Spinner/Spinner';

export class NoticePage extends Component {
    state ={
        noticeData:"",
        loading:true
    }
componentDidMount(){
  this.props.changeCoverTitle("All Notice Will Show Here")
    axios.get("/?getallnotice")
.then((response)=>{
    console.log(response.data);
    this.setState({noticeData:response.data, loading:false});
})

}

  render() {
  var noticeRender ;
  var noticeData = this.state.noticeData;
  noticeRender = Object.keys(noticeData)
  .map((i)=>{
    return (  <div class="col-sm-6">
    <div class="card ">
      <div class="card-body">
        <h5 class="card-title text-truncate" >{noticeData[i].notice_title}</h5>
        <p class="card-text text-truncate">{noticeData[i].notice_details}</p>
        <Link to={"/notice/"+noticeData[i].notice_id } class="btn btn-primary">View Notice</Link>
      </div>
    </div>
  </div>)
  })

    return (
      <>
 <div class="row">
  

   {this.state.loading?<Spinner/>:noticeRender}
</div>
      </>
    )
  }
}

export default NoticePage