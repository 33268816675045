import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./Component/Layout/Header";
import Layout from "./Component/Layout/Layout";
import CoursesPage from "./Pages/CoursesPage/CoursesPage";
import DepartmentPages from "./Pages/DepartmentPages/DepartmentPages";
import FilePage from "./Pages/FilePage/FilePage";
 
import Homepage from "./Pages/Homepage/Homepage";
import LecturesPage from "./Pages/LecturesPage/LecturesPage";
import MyProfile from "./Pages/MyProfile/MyProfile";
import Semesters from "./Pages/Semesters/Semesters";
import Teachers from "./Pages/Teachers/Teachers";
import PrivateRoute from "./Component/PrivateRoute";
import LoginPages from "./Pages/LoginPages/LoginPages";
import NoticePage from "./Pages/NoticePage/NoticePage";
import AddUpoads from "./Pages/Uploads/AddUpoads";
import NoticeSingleDetails from "./Pages/NoticePage/NoticeSingleDetails";
 import React, { Component } from 'react'
import axios from "axios";
import PublicRoute from "./Component/PublicRoute";
 
 export class App extends Component {

  state = {
    coverTitle :"HomePage",
    isLogged:false,
    token:"",
    username:"",
    user_id:"",
     
  }
  async  componentWillMount(){
      if(localStorage.hasOwnProperty('token')){
      var token = localStorage.getItem("token");
      this.setState({token:token, isLogged:true});
      }
}
componentDidMount(){
  axios.get("/?getuser")
  .then((response)=>{
    console.log(response.data)
  })
  .catch((error)=>{
localStorage.removeItem("token")
this.setState({isLogged:false})
  })
}
 

  changeCoverTitle =(title)=> {
    this.setState({coverTitle:title})
  }

  setLoggedin = (e)=>{
    var data = e;
    this.setState({isLogged:true, token:data.token});
    localStorage.setItem('token' ,data.token );
  }

  setLoggedOut = ()=>{
    if(localStorage.getItem("token")){
      localStorage.removeItem("token");
    }
    this.setState({isLogged:false})
  }

  
 

   render() {
    axios.defaults.headers.common['Authorization'] = this.state.token;
     return (
       <>
           <BrowserRouter>
           <Layout coverTitle={this.state.coverTitle} isLogged={this.state.isLogged} setLoggedOut={this.setLoggedOut}> 
      <Routes>
    
        <Route path="/">
      
        <Route element={<PublicRoute resticted={this.state.isLogged} />}>
       
        <Route path="/login" element={<LoginPages setLoggedin={this.setLoggedin} isLogged={this.props.isLogged} changeCoverTitle={this.changeCoverTitle} />} />
       
        </Route>
 
      


        
        <Route element={<PrivateRoute isLogged={this.state.isLogged} />}>
        <Route path="/profile" element={<MyProfile changeCoverTitle={this.changeCoverTitle} />} />
          <Route path="/teachers" element={<Teachers changeCoverTitle={this.changeCoverTitle} />} />
          <Route path="/department" element={<DepartmentPages changeCoverTitle={this.changeCoverTitle}/>} />
          <Route path="/department/:dept_id" element={<Semesters changeCoverTitle={this.changeCoverTitle}/>} />
          <Route path="/department/:dept_id/semester/:sem_id" element={<CoursesPage changeCoverTitle={this.changeCoverTitle}/>} />
          <Route path="/department/:dept_id/semester/:sem_id/course/:course_id" element={<LecturesPage changeCoverTitle={this.changeCoverTitle}/>} />
          <Route path="/lecture/:lecture_id" element={<FilePage changeCoverTitle={this.changeCoverTitle}/>} />
          <Route path="/notice" element={<NoticePage changeCoverTitle={this.changeCoverTitle}/>} />
           <Route path="/notice/:notice_id" element={<NoticeSingleDetails changeCoverTitle={this.changeCoverTitle}/>} />
           <Route path="/upload/add" element={<AddUpoads changeCoverTitle={this.changeCoverTitle}/>} />
           <Route index element={<Homepage  changeCoverTitle={this.changeCoverTitle}  isLogged={this.state.isLogged} />} /> 
          </Route>






       
          
          
          {/* <Route path="login" element={<Login />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="/teachers" element={<Teachers />} />
          <Route path="/officers" element={<Officer />} />
          <Route path="/students">
            <Route index element={<Students />} />
            <Route path=":studentId" element={<Single />} />
            <Route path="new" element={<New />} />
          </Route> */}
         
        </Route>
     
      </Routes>   </Layout> 
    </BrowserRouter>
       </>
     )
   }
 }
 
 export default App
 
